.login {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  input[type='email'],
  input[type='password'],
  a,
  button {
    width: 300px;
  }
}

.forgot {
  text-decoration: underline;
  font-size: 0.8rem;
  text-align: center;
}
