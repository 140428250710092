.grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: minmax(120px, 1fr);
  gap: 1rem;
}

.techStackImg {
  img {
    object-fit: contain;
    filter: grayscale(1);
    transition: 0.5s;
  }

  &:hover {
    img {
      filter: unset;
    }
  }
}
