.accordion {
  margin-bottom: var(--bs-nav-link-padding-y);

  svg {
    width: 1rem;
    margin-right: 1rem;
  }

  a {
    font-size: 0.85rem;
  }
}

.offcanvas {
  :global(.nav-link.active) {
    color: gray;
  }
}

.user {
  margin-top: 0.5rem;
  margin-bottom: 0;
  font-size: 0.85rem;
  color: var(--bs-gray-600);
}
