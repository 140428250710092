//$primary: #29b9d2;

@import '../../node_modules/bootstrap/scss/bootstrap';

:root {
  --swiper-pagination-color: var(--bs-primary);
}

body {
  min-height: 100%;
  background-color: #fcfcff;
  padding: 0;
  margin: 0;
  font-family: 'Lato', Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

*:focus {
  outline: 0;
}

button {
  background: none;
  border: 0;
  outline: 0;
}

textarea {
  resize: none;
}

.app {
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

form {
  input[type='number'],
  input[type='time'],
  input[type='date'],
  input[type='password'],
  input[type='email'],
  input[type='search'],
  input[type='text'] {
    background-color: transparent;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;

    &:focus {
      box-shadow: unset;

      ~ label {
        color: var(--bs-primary);
      }
    }

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  } //input

  input[type='date'],
  input[type='time'] {
    position: relative;

    //&::-webkit-calendar-picker-indicator {
    //  position: absolute;
    //  top: 0;
    //  right: 0;
    //  width: 100%;
    //  height: 100%;
    //  padding: 0;
    //  color: transparent;
    //  background: transparent;
    //}
  }
} //form

div.form-floating {
  > label {
    opacity: 0.65;
    //padding-top: 0.5rem;

    &:after {
      background-color: transparent !important;
    }
  }

  &.extended {
    label {
      //width: 100%;

      div span {
        transition: transform 0.1s ease-in-out;
      }
    }

    .form-control:focus ~ label div span,
    .form-control:not(:placeholder-shown) ~ label div span {
      //transform: translateX(4.5rem);
    }
  }

  &.textarea {
    //fix for floating labels and textareas
    &:before {
      content: '';
      position: absolute;
      top: 1px; /* border-width (default by BS) */
      left: 1px; /* border-width (default by BS) */
      width: calc(100% - 30px); /* to show scrollbar */
      height: 32px;
      border-radius: 4px; /* (default by BS) */
      background-color: #ffffff;
    }

    textarea.form-control {
      padding-top: 32px;
      min-height: 120px;
    }
  }

  div.spinner-border {
    position: absolute;
    top: calc(50% - 6px);
    left: 50%;
  }

  &.disabled {
    label {
      opacity: 0.2;
    }

    .form-control {
      opacity: 0.2;
    }
  }
}

label.form-label {
  padding: 1rem 0.75rem;
}

div.form-check label {
  cursor: pointer;
}

span.input-group-text {
  height: 58px;
}

.fs-7 {
  font-size: 0.85rem !important;
}
.fs-8 {
  font-size: 0.65rem !important;
}

$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%
);
@each $breakpoint in map-keys($grid-breakpoints) {
  @each $size, $length in $sizes {
    @include media-breakpoint-up($breakpoint) {
      .w-#{$breakpoint}-#{$size} {
        width: $length !important;
      }
    }
  }
}

.bg-gray-1 {
  background-color: #f8f9fa !important;
}

.bg-gray-2 {
  background-color: #e9ecef !important;
}

.bg-gray-3 {
  background-color: #dee2e6 !important;
}

.bg-gray-4 {
  background-color: #ced4da !important;
}

.bg-gray-5 {
  background-color: #adb5bd !important;
}

.bg-gray-7 {
  background-color: #495057 !important;
}

.bg-gray-8 {
  background-color: #343a40 !important;
}

.bg-gray-9 {
  background-color: #212529 !important;
}

.bg-black {
  background-color: #000 !important;
}

.fs-7 {
  font-size: 0.85rem !important;
}
.fs-8 {
  font-size: 0.65rem !important;
}

.pre-wrap {
  white-space: pre-wrap;
}
