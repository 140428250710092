.footer {
  width: 100%;
  height: 75px;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  p {
    color: hsla(236, 14%, 43%, 1);
    margin: 0;
  }
}
