.header {
  width: 100%;
  height: 90px;
  position: absolute;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(179, 183, 220, 0.25);
  display: flex;
  top: 0;
  left: 0;

  .links {
    a {
      display: flex;
      text-align: center;
      align-items: center;
      height: 100%;
      border-right: 1px solid var(--bs-primary);
      padding: 0 1rem;

      &:first-child {
        border-left: 1px solid var(--bs-primary);
      }

      &:global(.active),
      &:hover {
        background-color: var(--bs-primary);
        color: white !important;
      }
    }
  }
}
