.card {
  background-color: #ffffff;
  box-shadow: 0px 0px 3px rgba(100, 107, 134, 0.25);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 1rem;
  position: relative;
  overflow: hidden;

  &:global(.disabled) {
    span {
      opacity: 0.4;
    }
  }
}

.soon {
  position: absolute;
  font-size: 0.75rem;
  background-color: var(--bs-gray-300);
  width: 100px;
  line-height: 1;
  padding: 0.25rem;
  transform: rotate(45deg);
  right: -25px;
  top: 9px;
}
